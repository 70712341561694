import { FC, useState } from 'react';
import Input from './Input';
import { Eye, EyeOff } from 'lucide-react';
import { Props } from './@types/input';

const PasswordInput: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      rightIcon={
        <div className="cursor-pointer" onClick={() => setShowPassword((value) => !value)}>
          {showPassword ? <Eye /> : <EyeOff />}
        </div>
      }
    />
  );
};

export default PasswordInput;
