import { FC, useMemo } from 'react';
import { Props } from './@types/button';
import { Theme } from '@radix-ui/themes';

const Button: FC<Props> = ({
  leftIcon,
  rightIcon,
  label,
  className = '',
  textClassName = '',
  kind = 'primary',
  disabled
}) => {
  const colorClassName = useMemo(() => {
    if (disabled) {
      switch (kind) {
        case 'primary':
          return 'bg-white opacity-30 border-normal';
        default:
          return 'bg-gray-3';
      }
    }
    switch (kind) {
      case 'primary':
        return 'border-normal hover:bg-gray-primary active:bg-black';
      case 'secondary':
        return 'border-blue-primary hover:bg-blue-light-5 active:bg-blue-light-3';
      case 'tertiary':
        return 'shadow shadow-[#A6AFC366] hover:bg-gray-2 active:bg-blue-light-5';
    }
  }, [kind, disabled]);
  const textColorClassName = useMemo(() => {
    if (disabled) {
      switch (kind) {
        case 'secondary':
        case 'tertiary':
          return 'text-secondary';
      }
    }
    switch (kind) {
      case 'secondary':
      case 'tertiary':
        return 'text-blue-primary';
    }
  }, [kind, disabled]);

  const iconColorClassName = useMemo(() => {
    if (!disabled) {
      switch (kind) {
        case 'primary': {
          return 'active:text-white';
        }
        case 'secondary':
          return 'active:text-black';
      }
    }
  }, [kind, disabled]);

  return (
    <Theme>
      <div
        className={`${className} ${colorClassName} cursor-pointer ease-in duration-100 border-[1px] rounded-xl px-4 py-5 w-fit`}>
        <div
          className={`text-base font-semibold leading-6 flex gap-2 items-center justify-center icon-suffix ${iconColorClassName}`}>
          {leftIcon && <div className={`${textClassName && textClassName}`}>{leftIcon}</div>}
          <div className={`${textClassName && textClassName} ${textColorClassName}`}>{label}</div>
          {rightIcon && <div className={`${textClassName && textClassName}`}>{rightIcon}</div>}
        </div>
      </div>
    </Theme>
  );
};

export default Button;
