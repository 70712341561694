import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationDE from './locales/de.json';
import translationEN from './locales/en.json';

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE }
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'de'],
    debug: false,
    resources
  });

export default i18next;
