import PasswordInput from '../common/PasswordInput';
import Input from '../common/Input';
import { ArrowRight } from 'lucide-react';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <Input placeholder={t('email')} type="email" className="mb-4" />
      <PasswordInput placeholder={t('password')} />
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2 text-gray-secondary text-sm">
          <input
            type="checkbox"
            className="border-inputBorder w-[20px] h-[20px] rounded border-[1px]"
          />
          {t('rememberMe')}
        </div>
        <div className="font-medium text-sm cursor-pointer">{t('forgotPassword')} ?</div>
      </div>
      <Button label={t('signIn')} rightIcon={<ArrowRight />} className="mt-4 mb-2 w-full " />
    </div>
  );
};

export default LoginForm;
