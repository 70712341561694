import { useTranslation } from 'react-i18next';
import LoginForm from '../components/Login/LoginForm';
const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[100vh] w-[100vw] flex overflow-hidden">
      <div className="flex flex-col justify-center items-center h-full w-[46.7%]">
        <div className="max-w-[432px]">
          <div className="flex flex-col items-center mb-8">
            <img className="h-[70px] w-fit" src="/assets/images/logo.png" alt="Logo" />
            <div className="text-[40px] mt-6 mb-2 leading-[50px] font-semibold">Process Bee</div>
            <div className="text-sm text-sub text-center">{t('welcomeText')}</div>
          </div>
          <LoginForm />
          <div>
            <div className="text-center">
              <div className="text-gray-secondary text-sm">
                {t('dontHaveAccount')}?{' '}
                <span className="text-black underline">{t('contact')} Process Be</span>
              </div>
            </div>
            <div className="mt-2 text-[10px] text-gray-secondary text-center">
              {t('byContinueTerm')} <span className="underline">{t('termOfService')} </span>{' '}
              {t('and')} <span className="underline">{t('privacyPolicy')}</span>.
            </div>
            <div className="mt-8">
              <p className="text-sm text-body text-center">{t('trustBy')}</p>
              <div className="flex mt-2 items-center justify-center gap-7">
                <img src="/assets/images/headspace.png" alt="logo" />
                <img src="/assets/images/shopify.png" alt="logo" />
                <img src="/assets/images/revolut.png" alt="logo" />
                <img src="/assets/images/duolingo.png" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 h-full">
        <img className="h-auto w-full" src="/assets/images/sign-in-panel.png" alt="Left panel" />
      </div>
    </div>
  );
};

export default LoginPage;
